<template>
  <el-form
    ref="form"
    v-loading="loading"
    :disabled="isClick"
    class="support_form"
    :rules="rules"
    :model="form"
    label-width="120px"
  >
    <el-form-item :label="LP.lang_title" prop="title">
      <el-input v-model.trim="form.title" class="support_input" />
    </el-form-item>
    <el-form-item :label="LP.lang_integration_protocol" prop="integration_protocol">
      <el-select
        v-model="form.integration_protocol"
        placeholder="Select"
        class="support_select"
      >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="form.integration_protocol === 'SDK'"
      :label="LP.lang_question_type"
      prop="question_type"
    >
      <el-select v-model="form.question_type" placeholder="Select" class="support_select">
        <el-option v-for="item in lists" :key="item.val" :label="item.label" :value="item.val" />
      </el-select>
    </el-form-item>
    <template v-if="isForm">
      <el-form-item v-if="showQuestion" :label="LP.lang_programing_language" prop="programing_language">
        <el-checkbox-group v-model="form.programing_language" class="support_checkbox">
          <el-checkbox label="C/C++" name="programing_language" />
          <el-checkbox label="C#" name="programing_language" />
          <el-checkbox label="Java" name="programing_language" />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="showQuestion" :label="LP.lang_operation_system" prop="operation_system">
        <el-checkbox-group v-model="form.operation_system" class="support_checkbox">
          <el-checkbox label="Windows32" name="operation_system" />
          <el-checkbox label="Windows64" name="operation_system" />
          <el-checkbox label="Linux32" name="operation_system" />
          <el-checkbox label="Linux64" name="operation_system" />
          <el-checkbox label="Android" name="operation_system" />
          <el-checkbox label="ios" name="operation_system" />
        </el-checkbox-group>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item v-if="showQuestion" :label="LP.lang_programing_language">
        <el-input v-model="form.programing_language" class="support_input" />
      </el-form-item>
      <el-form-item v-if="showQuestion" :label="LP.lang_operation_system">
        <el-input v-model="form.operation_system" class="support_input" />
      </el-form-item>
    </template>
    <el-form-item
      v-if="form.question_type === 'Trouble shootina'"
      :label="LP.lang_current_sdk_version"
      prop="current_sdk_version"
    >
      <el-input v-model.trim="form.current_sdk_version" class="support_input" />
    </el-form-item>
    <el-form-item v-if="showMdoel" :label="LP.lang_dahua_product_model" prop="dahua_product_model">
      <el-input v-model.trim="form.dahua_product_model" class="support_input" />
    </el-form-item>
    <el-form-item
      v-if="showFirmware"
      :label="LP.lang_product_firmware"
      prop="product_firmware"
    >
      <el-input v-model.trim="form.product_firmware" class="support_input" />
    </el-form-item>
    <el-form-item
      v-if="form.integration_protocol === 'DHOP'"
      :label="LP.lang_dhop_firmware"
      prop="dhop_firmware"
    >
      <el-input v-model.trim="form.dhop_firmware" class="support_input" />
    </el-form-item>
    <el-form-item
      v-if="form.integration_protocol === 'SDK' && form.question_type"
      :label="LP.lang_project_description	"
      prop="project_description"
    >
      <el-input v-model.trim="form.project_description" type="textarea" class="support_textarea" />
    </el-form-item>
    <el-form-item :label="LP.lang_contents" prop="contents">
      <el-input v-model.trim="form.contents" type="textarea" class="support_textarea" />
    </el-form-item>
    <el-form-item v-if="showRelated" :label="LP.lang_lang_project_related" prop="project_related">
      <el-select v-model="form.project_related" placeholder="Yes/No" class="support_select">
        <el-option
          v-for="item in selects"
          :key="item.vale"
          :label="item.label"
          :value="item.vale"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="LP.lang_attachment" prop="attachment">
      <el-input v-model.trim="form.attachment" :disabled="true" class="support_attachment" />
      <el-upload
        v-if="isForm"
        ref="upload"
        class="upload-demo"
        :action="uploadUrl"
        :on-change="handleChange"
        :show-file-list="showFileList"
        :on-success="handleSuccess"
        :file-list="fileList"
        accept=".gif, .png, .jpeg, .jpg, .pdf, .xls, .xlsx, .pdf, .doc, .docx, .zip, .rar, .gz, .7z"
      >
        <el-button size="small" type="primary" class="upload">{{LP.lang_browser}}</el-button>
      </el-upload>
      <div v-if="isForm" class="presentation">
        {{LP.lang_currently_only_supported}}
      </div>
    </el-form-item>
    <el-form-item v-if="isForm" class="center">
      <el-button type="primary" round class="submit" @click="handleSubmit">{{LP.lang_submit}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { supportSubmitApi } from '@/api/support'
import { _axios } from '@/plugins/axios.js'
export default {
  props: {
    isClick: {
      type: Boolean,
      default: false
    },
    isForm: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        title: '',
        integration_protocol: '',
        programing_language: [],
        operation_system: [],
        current_sdk_version: '',
        dahua_product_model: '',
        product_firmware: '',
        dhop_firmware: '',
        project_description: '',
        project_related: '',
        attachment: '',
        contents: '',
        question_type: ''
      },
      attachmentURL: '',
      // integration_protocol 选项
      value: '',
      options: [
        { value: 'SDK', label: 'SDK' },
        { value: 'CGI', label: 'CGI' },
        { value: 'Onvif', label: 'Onvif' },
        { value: 'DHOP', label: 'DHOP' },
        { value: 'Other protocols', label: 'Other protocols' }
      ],
      // SDK 选择的 question_type 中的选项
      val: '',
      lists: [
        { val: 'Integration Consultation', label: 'Integration Consultation' },
        { val: 'Trouble shootina', label: 'Trouble shootina' }
      ],
      // project_related 选项
      vale: '',
      selects: [
        { vale: '1', label: 'Yes' },
        { vale: '2', label: 'No' }
      ],
      showFileList: false,
      fileList: [], // 上传文件列表
      loading: false,
      uploadUrl: _axios.defaults.baseURL + '/home/upload'
    }
  },
  computed: {
    showQuestion() {
      return (
        (this.form.question_type === 'Trouble shootina' ||
          this.form.question_type === 'Integration Consultation') &&
        this.form.integration_protocol === 'SDK'
      )
    },
    showMdoel() {
      return (
        this.form.question_type === 'Trouble shootina' ||
        this.form.integration_protocol === 'DHOP' ||
        this.form.integration_protocol === 'CGI' ||
        this.form.integration_protocol === 'Onvif'
      )
    },
    showFirmware() {
      return (
        this.form.question_type === 'Trouble shootina' ||
        this.form.integration_protocol === 'DHOP' ||
        this.form.integration_protocol === 'CGI' ||
        this.form.integration_protocol === 'Onvif'
      )
    },
    showRelated() {
      return (
        this.form.integration_protocol === 'Other protocols' ||
        this.form.integration_protocol === 'DHOP' ||
        this.form.integration_protocol === 'CGI' ||
        this.form.integration_protocol === 'Onvif'
      )
    }

  },
  methods: {
    // 上传文件更改
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    // 文件上传成功
    handleSuccess(response, file, fileList) {
      const data = response.data
      this.attachmentURL = data
      this.form.attachment = file.name
    },
    // 提交问题
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.attachment = this.attachmentURL
          this.loading = true
          supportSubmitApi({ ...this.form }).then(res => {
            this.loading = false
            if (res.message.toLowerCase() === 'ok') {
              this.$message({
                message: 'success',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.$refs.form.resetFields()
                  this.$refs.upload.clearFiles()
                  const page = 1
                  this.$emit('getQuestionDetail', page)
                }
              })
            }
          }).catch(e => e)
        } else {
          this.$message({
            message: 'Required with * cannot be empty !',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.support_form {
  margin-top: 20px;
  .support_input,
  .support_select,
  .support_checkbox {
    border-left: #dadada solid 1px;
    padding-left: 20px;
    /deep/input {
      background-color: #f5f5f5;
    }
  }
}
.support_textarea {
  border-left: #dadada solid 1px;
  padding-left: 20px;
}
/deep/.el-textarea__inner {
  background: #f5f5f5;
  height: 227px;
}
.support_form {
  /deep/.el-form-item__error {
    padding-left: 20px;
  }
}
.el-form-item {
  /deep/.el-form-item__label {
    text-align: right;
    line-height: normal;
  }
}
.support_attachment {
  display: inline-block;
  max-width: 400px;
  border-left: #dadada solid 1px;
  padding-left: 20px;
  /deep/.el-input__inner {
    background-color: #f5f5f5;
  }
}
.upload {
  width: 104px;
  height: 40px;
  background: #999999;
  border-color: snow;
}
.upload-demo {
  margin-left: 20px;
  display: inline-block;
}
.submit {
  width: 120px;
  margin-top: 42px;
  margin-right: 45px;
  background-color: #0e5fac;
}
.center {
  text-align: center;
}
.presentation {
  color: #f56c6c;
  font-size: 12px;
  padding-left: 20px;
}
</style>
