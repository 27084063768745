import { render, staticRenderFns } from "./GetSupport.vue?vue&type=template&id=74d6f574&scoped=true&"
import script from "./GetSupport.vue?vue&type=script&lang=js&"
export * from "./GetSupport.vue?vue&type=script&lang=js&"
import style0 from "./GetSupport.vue?vue&type=style&index=0&id=74d6f574&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d6f574",
  null
  
)

export default component.exports