import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  supportList: '/support/get_support', // get support 问题列表
  supportSubmit: '/support/get_support', // get support问题提交
  supportInfo: '/support/get_support_replyList', // Get support信息回复记录
  supportReply: '/support/get_support_replyCreate', // Get support信息回复创建
  supportQsDetail: '/support/get_support_question' // get support 问题列表详情
}
// get support 问题列表
export const supportListApi = (params) => _axios.get(apiUrl.supportList + `?page=${params}`)
// get support问题提交
export const supportSubmitApi = (params) => _axios.post(apiUrl.supportSubmit, params)
// Get support信息回复记录
export const supportInfoApi = (params) => _axios.post(apiUrl.supportInfo, params)
// Get support信息回复创建
export const supportReplyApi = (params) => _axios.post(apiUrl.supportReply, params)
// get support 问题列表详情
export const supportQsDetailApi = (params) => _axios.get(apiUrl.supportQsDetail + `?question_id=${params}`)
