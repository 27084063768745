<template>
  <div class="container">
    <section class="ensemble ">
      <div class="mq-title">
        <i class="el-icon-arrow-left mq-return" @click="handleReturn"></i>
        <span>{{ LP.lang_my_questions }}</span>
      </div>

      <question-form
        ref="refs"
        :is-form="isForm = false"
        :is-click="isClick"
      />

      <div>
        <div class="mq-answer">
          <span>{{ LP.lang_answers }}</span>
        </div>
        <div class="mq-content">
          <div
            v-for="(item, index) in answerList"
            :key="index"
            :class="item.user_email == 'Dahua'?'atalk':'btalk'"
          >
            <span v-if="item.user_email === 'Dahua'" class="user">
              {{ item.user_email }}
              <span v-if="!item.file_url" class="message">{{ item.file_name }}</span>
              <span
                v-if="item.file_url"
                class="file"
                @click="handleFile(item.file_url)"
              >{{ item.file_name }}</span>
            </span>
            <span v-if="item.user_email !== 'Dahua'" class="user">
              <span v-if="!item.file_url" class="message">{{ item.file_name }}</span>
              <span
                v-if="item.file_url"
                class="file"
                @click="handleFile(item.file_url)"
              >{{ item.file_name }}</span>
              {{ item.user_email }}
            </span>
          </div>
        </div>

        <div class="mq-textarea">
          <el-input
            v-model="file_name"
            type="textarea"
            autosize
            placeholder="Please enter content"
            class="mq-input"
            :disabled="isShow"
          />
          <div
            class="presentation"
          >{{ LP.lang_currently_only_supported }}</div>
        </div>
      </div>

      <div class="mq-footer">
        <div class="mq-browser">
          <el-upload
            ref="upload"
            :action="uploadUrl"
            :on-change="handleChange"
            :data="{show: show}"
            :show-file-list="showFileList"
            :on-success="handleSuccess"
            :file-list="fileList"
            :before-upload="handleBeforeUpload"
            accept=".gif, .png, .jpeg, .jpg, .pdf, .xls, .xlsx, .pdf, .doc, .docx, .zip, .rar, .gz, .7z"
          >
            <i class="el-icon-upload2 mq-bro"></i>
            <span>{{ LP.lang_browser }}</span>
          </el-upload>
        </div>
        <div class="mq-delete">
          <el-button type="primary" @click="handleDelete">{{ LP.lang_delete_attachment }}</el-button>
        </div>
        <div class="mq-button">
          <el-button type="primary" @click="handleAdd">{{ LP.lang_reply }}</el-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { supportQsDetailApi, supportInfoApi, supportReplyApi } from '@/api/support'
import { _axios } from '@/plugins/axios.js'
import QuestionForm from '@/components/support/QuestionForm'

export default {
  components: {
    QuestionForm
  },
  data() {
    return {
      isClick: true,
      answerList: [],
      isShow: false,
      information: '',
      fileList: [],
      showFileList: false,
      file_name: '',
      show: 1,
      uploadUrl: _axios.defaults.baseURL + '/home/upload'
    }
  },
  created() {
    this.getQuestionDetail(this.$route.query.question_id)
    this.getAnswer(this.$route.query.question_id)
  },
  methods: {
    // 返回原来页面
    handleReturn() {
      this.$router.push({
        name: 'getSupport'
      })
    },
    // 获取问题详情
    getQuestionDetail(question_id) {
      supportQsDetailApi(question_id).then(res => {
        this.$refs.refs.form = res.data
      }).catch(e => e)
    },
    // 获取信息回复记录
    getAnswer(question_id) {
      supportInfoApi({
        question_id
      }).then(res => {
        this.answerList = res.data
      }).catch(e => e)
    },
    // 点击回答中带有url属性
    async handleFile(url) {
      await this.getAnswer(this.$route.query.question_id)
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.click()
    },
    // 切换文件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    // 文件上传成功回调
    handleSuccess(response, file, fileList) {
      this.file_name = file.name
      this.information = response.data
      this.isShow = true
    },
    // 上传文件之前的回调 限制上传文件大小
    handleBeforeUpload(file) {
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isLt50M) {
        this.$message({
          message: 'Upload file size cannot exceed 50M',
          type: 'warning'
        })
      }
    },
    // 清除选中的文件
    handleDelete() {
      this.file_name = ''
      this.isShow = false
    },
    // 添加回复
    handleAdd() {
      if (this.file_name === '') {
        this.$message({
          message: 'Please Enter Content!',
          type: 'error'
        })
        return
      }
      supportReplyApi({
        file_name: this.file_name,
        question_id: this.$route.query.question_id,
        answer: this.information === '' ? this.file_name : this.information,
        type: this.isShow === false ? 0 : 1,
        title: this.$route.query.title
      }).then(res => {
        this.getAnswer(this.$route.query.question_id)
      }).catch(e => e)
      this.file_name = ''
      this.information = ''
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped >
.ensemble {
  margin: 0 auto;
  padding: 23px 53px 60px 60px;
  background-color: #fff;
}
.mq-title {
  text-align: center;
  color: #0e5fac;
  font-size: 20px;
  font-weight: 700;
}
.mq-return {
  float: left;
  font-size: 24px;
  color: #333;
  padding-top: 3px;
  cursor: pointer;
}
.presentation {
  margin-top: 5px;
  color: #f56c6c;
  font-size: 12px;
  padding-left: 20px;
}
.mq-answer {
  text-align: center;
  border: 1px #666 dashed;
  margin-top: 20px;
  padding: 10px 0;
  color: #0e5fac;
  font-size: 18px;
  font-weight: 600;
}
.mq-content {
  background-color: #f5f5f5;
}
.atalk {
  padding: 10px;
  width: 50%;
  word-break: break-all;
  .user {
    vertical-align: top;
  }
  .message {
    display: inline-block;
    background-color: #fff;
    width: auto;
    max-width: 80%;
    padding: 10px;
    position: relative;
    margin-left: 20px;
  }
  .message::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 5px;
    border-top: 7px solid transparent;
    border-right: 14px solid #fff;
    border-bottom: 7px solid transparent;
  }
  .file {
    display: inline-block;
    background-color: #fff;
    width: auto;
    max-width: 80%;
    padding: 10px;
    position: relative;
    margin-left: 20px;
    cursor: pointer;
    color: #0e5fac;
    font-weight: 600;
  }
  .file::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 5px;
    border-top: 7px solid transparent;
    border-right: 14px solid #fff;
    border-bottom: 7px solid transparent;
  }
}
.btalk {
  padding: 10px;
  text-align: right;
  width: 50%;
  margin-left: 50%;
  .user {
    vertical-align: top;
  }
  .message {
    display: inline-block;
    background-color: #fff;
    width: auto;
    max-width: 80%;
    padding: 10px;
    position: relative;
    margin-right: 20px;
  }
  .message::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 5px;
    border-top: 7px solid transparent;
    border-left: 14px solid #fff;
    border-bottom: 7px solid transparent;
  }
  .file {
    display: inline-block;
    background-color: #fff;
    width: auto;
    max-width: 80%;
    padding: 10px;
    position: relative;
    margin-right: 20px;
    cursor: pointer;
    color: #0e5fac;
    font-weight: 600;
  }
  .file::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 5px;
    border-top: 7px solid transparent;
    border-left: 14px solid #fff;
    border-bottom: 7px solid transparent;
  }
}
.mq-input {
  margin-top: 20px;
  /deep/ input {
    background-color: #f5f5f5;
    border: none;
  }
}
.mq-textarea {
  /deep/ .el-textarea__inner {
    height: 20px;
  }
}
.mq-footer {
  margin-top: 20px;
}
.mq-browser {
  float: left;
  color: #333;
  cursor: pointer;
  height: 40px;
  padding: 9px 0 9px 0;
}
.mq-bro {
  border: 1px solid #0e5fac;
  border-radius: 50%;
  padding: 6px;
  background-color: #0e5fac;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  margin-right: 10px;
}
.mq-delete {
  float: left;
  margin-left: 10px;
  /deep/ button {
    background-color: #0e5fac;
  }
}
.mq-button {
  float: right;
  /deep/button {
    background-color: #0e5fac;
    /deep/span {
      padding-bottom: 7px;
    }
  }
}
</style>
