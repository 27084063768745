<template>
  <div class="getsupport">
    <public-banner :banner-info="bannerInfo" />

    <div class="container">
      <section class="ensemble support-main-right">
        <question-form
          ref="refs"
          :rules="rules"
          :is-form="isForm = true"
          @getQuestionDetail="getQuestionDetail"
        />

        <template>
          <public-title :title="LP.lang_my_questions" />
          <el-table
            :data="questionList"
            :header-cell-style="{background: '#0e5fac', color: '#fff', 'text-align': 'center'}"
            border
            empty-text="No Data"
            style="width: 100%; margin-top: 17px"
          >
            <el-table-column prop="integration_protocol" :label="LP.lang_category" width="215" align="center" />
            <el-table-column :label="LP.lang_title" align="left">
              <template slot-scope="props">
                <template style="border-bottom: 1px solid #000;">
                  <i v-if="props.row.type === '0'" class="left-arrow el-icon-remove"></i>
                  <i v-else class="left-arrow el-icon-success"></i>
                  {{ props.row.title }}
                </template>
                <i
                  class="el-icon-arrow-right right-arrow"
                  @click="handleQuestion(props.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :pager-count="5"
          :current-page="page"
          @current-change="currentChangeHandler"
        />

      </section>
    </div>
  </div>
</template>

<script>
import publicBanner from '@/components/public/PublicBanner'
import { mapActions } from 'vuex'
import PublicTitle from '@/components/public/PublicTitle'
import { supportListApi } from '@/api/support'
import { _axios } from '@/plugins/axios.js'
import QuestionForm from '@/components/support/QuestionForm'
export default {
  components: {
    publicBanner,
    PublicTitle,
    QuestionForm
  },
  data() {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      // 校验规则
      rules: {
        title: [{ required: true, trigger: 'blur' }],
        integration_protocol: [{ required: true, trigger: 'change' }],
        contents: [{ required: true, trigger: 'blur' }],
        question_type: [{ required: true, trigger: 'change' }],
        programing_language: [{ type: 'array', required: true, trigger: 'blur' }],
        operation_system: [{ type: 'array', required: true, trigger: 'blur' }],
        current_sdk_version: [{ required: true, trigger: 'blur' }],
        dahua_product_model: [{ required: true, trigger: 'blur' }],
        product_firmware: [{ required: true, trigger: 'blur' }],
        project_description: [{ required: true, trigger: 'blur' }],
        project_related: [{ required: true, trigger: ['blur', 'change'] }],
        dhop_firmware: [{ required: true, trigger: 'blur' }]
      },
      questionList: [], // 问题列表
      total: 0, // 问题总数
      page: 1, // 哪一页
      uploadUrl: _axios.defaults.baseURL + '/home/upload'
    }
  },
  created() {
    this.getBanner()
    this.getQuestionDetail(this.page)
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 32 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 获取问题列表
    async getQuestionDetail(page) {
      const res = await supportListApi(page)
      this.questionList = res.data.partnerInfo
      this.total = res.data.count
    },
    // 分页页面切换
    currentChangeHandler(val) {
      this.page = val
      this.getQuestionDetail(this.page)
    },
    // 问题详情页跳转
    handleQuestion(row) {
      this.$router.push({
        name: 'questionDetail',
        query: {
          question_id: row.id,
          title: row.title
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>

.ensemble {
  margin: 0 auto;
  padding: 23px 53px 60px 60px;
  background-color: #fff;
}

/deep/.public-title {
  text-align: left;
  .title {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 29px;
  }
}
.left-arrow {
  font-size: 24px;
  vertical-align: middle;
  padding-right: 12px;
}
/deep/ .el-icon-remove {
  color: #ffe699;
}
/deep/ .el-icon-success {
  color: #68b4fc;
}
.right-arrow {
  float: right;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}
.el-pagination {
  text-align: right;
  margin-top: 29px;
  padding: 0;
  /deep/ li:not(.disabled).active {
    background-color: #409EFF !important;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ li:not(.disabled):hover {
    color: #409EFF !important;
  }
}
</style>
